import React, { FunctionComponent, useEffect, useState } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import clsx from "clsx"
import {
  faExclamation,
  faGamepad,
  faHeartbeat,
  faPlay,
  faShieldAlt,
  faSmileBeam,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import NewTimeline from "../components/NewTimeline"

// import "animate.css"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import cylinder from "../images/fire-cylinder.mp4"
import successLoop from "../images/success-loop.mp4"
import successFailure from "../images/success-failure.mp4"
import newtedx from "../images/tedxdrop.png"

import areasoflifevertical from "../images/areas-of-life-animation-vertical.mp4"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Button from "../components/Button"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Timeline, { TimelineEvent } from "../components/Timeline"
import reset from "../images/reset-life.gif"
import Ghosts from "../components/svg/Ghosts"
import OnScreen from "../components/OnScreen"
import TypedText from "../components/TypedText"
import { SuccessStory } from "../types"
import StartButton from "../components/StartButton"
import rocketCores from "../images/rocket-cores.gif"
import video from "../images/areas-of-life.mp4"
import mind from "../images/mindset.gif"
import emotional from "../images/emotional.gif"
import physical from "../images/physical.gif"
import relationship from "../images/relationship.gif"
import finance from "../images/finance.gif"

import tedx from "../images/tedx.png"

interface Props {
  data: any
}

const IndexPage: FunctionComponent<Props> = ({ data }) => {
  const stories = data.allContentfulSuccessStory.edges.map(
    (elem: any) => elem.node
  ) as SuccessStory[]

  const [pixelateImage, setPixelateImage] = useState(false)

  const newData = data.allContentfulHomePage.edges[0].node

  const events: TimelineEvent[] = [
    {
      color: "blue",
      direction: "NEUTRAL",
      icon: faGamepad,
      image: (
        <StaticImage
          alt="Game Starts"
          placeholder="blurred"
          src="../images/index-timeline-1.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepOne, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/middle.png"
          />
        </div>
      ),
      title: "Game Starts",
    },
    {
      color: "coral",
      direction: "DOWN",
      icon: faShieldAlt,
      image: (
        <StaticImage
          alt="Shields Weaken"
          placeholder="blurred"
          src="../images/index-timeline-2.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepTwo, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/low.png"
          />
        </div>
      ),
      title: "Shields Weaken",
    },
    {
      color: "coral",
      direction: "DOWN",
      icon: faExclamation,
      image: (
        <StaticImage
          alt="Critical Life Alert"
          placeholder="blurred"
          src="../images/failure.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepThree, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/critical.png"
          />
        </div>
      ),
      title: "Critical Life Alert",
    },
    {
      color: "blue",
      direction: "NEUTRAL",
      icon: faHeartbeat,
      image: (
        <StaticImage
          alt="Extra Life"
          placeholder="blurred"
          src="../images/extralife.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepFour, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/low.png"
          />
        </div>
      ),
      title: "Extra Life",
    },
    {
      color: "teal",
      direction: "UP",
      icon: faPlay,
      image: (
        <StaticImage
          alt="Learn To Play"
          placeholder="blurred"
          src="../images/cycle.png"
        />
      ),

      // message:"Use the Moore Momentum system to make it fun and exciting to set out on your new epic quest of figuring out WHO you currently are, WHAT will make you truly happy, and HOW to get it.",
      message: (
        <div className="text-link">
          {renderRichText(newData.stepFive, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/middle.png"
          />
        </div>
      ),
      title: "Learn How To Play",
    },
    {
      color: "teal",
      direction: "UP",
      icon: faThumbsUp,
      image: (
        <StaticImage
          alt="Obtain Upgrades"
          placeholder="blurred"
          src="../images/equation-of-life-v2.jpg"
        />
      ),
      // message:"It won't happen overnight, but as you gamify your habits one by one you'll start reaping the benefits of your upgraded avatar.",
      message: (
        <div className="text-link">
          {renderRichText(newData.stepSix, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/high.png"
          />
        </div>
      ),
      title: "Obtain Upgrades",
    },
    {
      color: "teal",
      direction: "UP",
      icon: faSmileBeam,
      image: (
        <div
          dangerouslySetInnerHTML={{
            __html: `<video className="app__backgroundVideo" autoPlay loop muted playsinline>
      <source src=${cylinder} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
          }}
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepSeven, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
          <StaticImage
            alt="Shields Weaken"
            placeholder="blurred"
            src="../images/full.png"
          />
        </div>
      ),
      title: "Winning At LIfe",
    },
  ]

  const newEvents: TimelineEvent[] = [
    {
      color: "teal",
      direction: "NEUTRAL",
      iconImage: mind,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={mind}
            />
          ),
          message: (
            <div className="text-link">
              Get your mind working FOR instead of against you by developing a
              growth mindset to allow you to fail forward, overcome obstacles,
              and give fear the finger on your level up journey.
            </div>
          ),
          title: "Mindset Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/mindset-before.webp"
            />
          ),
          message: (
            <div className="text-link m-auto text-coral">
              You're unable to level up your life because of a fixed mindset,
              limiting beliefs, and low self esteem.{" "}
            </div>
          ),
          title: "Mindset Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/mindset-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              Positive mindset, self love, continual self reflection and
              learning new skills to become the best version of yourself.{" "}
            </div>
          ),
          title: "Mindset Core After",
        },
      ],
    },

    {
      color: "teal",
      direction: "NEUTRAL",
      iconImage: finance,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores career finance"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={finance}
            />
          ),
          message: (
            <div className="text-link">
              Improve your professional life doing what you love and are great
              at, executing your purpose, and exponentially grow your wealth
              along the way.
            </div>
          ),
          title: "Career & Finance Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/finance-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You're constantly overwhelmed, procrastinating, and unable to get
              out of your comfort zone to take the necessary action to conquer
              your boss battles.{" "}
            </div>
          ),
          title: "Career & Finance Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/finance-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              You're running a startup business that combines your greatest
              strengths and passions to win the video game of life.{" "}
            </div>
          ),
          title: "Career & Finance Core After",
        },
      ],
    },

    {
      color: "blue",
      direction: "NEUTRAL",
      iconImage: relationship,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores relationship"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={relationship}
            />
          ),
          message: (
            <div className="text-link">
              Create and maintain deep, fulfilled relationships and gain the
              right allies to help win those boss battles.
            </div>
          ),
          title: "Relationships Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/relationship-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You've got poor game mechanics with others, continuously
              struggling to create close friendships and find/maintain healthy
              romantic relationships.{" "}
            </div>
          ),
          title: "Relationships Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/relationship-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              You've developed a daily routine to spend time with those who
              matter most and and have acquired the skills to build strategic
              partnerships for a successful and happy life.{" "}
            </div>
          ),
          title: "Relationships Core After",
        },
      ],
    },

    {
      color: "blue",
      direction: "NEUTRAL",
      iconImage: physical,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores physical health"
              placeholder="blurred"
              loading="lazy"
              className="m-auto"
              src={physical}
            />
          ),
          message: (
            <div className="text-link">
              Take care of your physical self to look good, feel good, and gain
              the energy and stamina to propel you to a lasting and meaningful
              life.
            </div>
          ),
          title: "Physical Health Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/physical-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You've become addicted to dopamine hits and low hanging fruit (not
              the good kind) that have compounded your unhealthy habits with
              eating, exercising, and sleep.{" "}
            </div>
          ),
          title: "Physical Health Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/physical-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              Your self aware of the key aspects of your health that you're able
              to personalize a healthy self care routine around to become the
              best version of yourself.{" "}
            </div>
          ),
          title: "Physical Health Core After",
        },
      ],
    },

    {
      color: "blue",
      direction: "NEUTRAL",
      iconImage: emotional,
      data: [
        {
          image: (
            <img
              alt="will moore 5 cores emotional health"
              className="m-auto"
              loading="lazy"
              placeholder="blurred"
              src={emotional}
            />
          ),
          message: (
            <div className="text-link">
              Manage stress effectively, express your passions regularly, and
              ensure the world is better for having you in it.
            </div>
          ),
          title: "Emotional Health Core",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/emotional-before.webp"
            />
          ),
          message: (
            <div className="text-link text-coral">
              You're constantly overwhelmed, stressed, and rarely make time for
              your passions in life.{" "}
            </div>
          ),
          title: "Emotional Health Core Before",
        },
        {
          image: (
            <StaticImage
              alt="will moore 5 cores mindset"
              placeholder="blurred"
              src="../images/emotional-after.webp"
            />
          ),
          message: (
            <div className="text-link text-blue">
              You effectively manage stress and regularly engage in activities
              that power up your video game avatar in order to live a fulfilled
              and meaningful life.
            </div>
          ),
          title: "Emotional Health Core After",
        },
      ],
    },
  ]

  function getTestimonials() {
    const order = ["Linus Stieldorf", "Mark Hansen", "Danny Lynch"]
    const testimonials = []

    for (let i = 0; i < order.length; i += 1) {
      for (let j = 0; j < stories.length; j += 1) {
        if (order[i] === stories[j].name) {
          const elem = stories[j]

          testimonials.push(
            <OnScreen
              className="bg-gray p-4 rounded-md shadow-md"
              classToAdd="slide-in-left"
              key={elem.id}
            >
              <div className="flex items-center mb-8">
                <div className="h-28 w-28">
                  <GatsbyImage
                    alt={elem.name}
                    className="rounded-full filter drop-shadow-lg"
                    image={elem.coverImage.gatsbyImageData}
                  />
                </div>

                <div className="flex-1 ml-8">
                  <p className="font-Orbitron mb-1 text-2xl">{elem.name}</p>

                  <p>{elem.title}</p>
                </div>
              </div>
              <p className="pb-4">{elem.testimonial.testimonial}</p>
            </OnScreen>
          )
        }
      }
    }

    return testimonials
  }

  const [text, setText] = useState("Gamify Your Habits")

  useEffect(() => {
    const interval = setInterval(() => {
      setText(t => {
        if (t === newData.headline) {
          return "We make change FUN!"
        }
        if (t === "We make change FUN!") {
          return newData.headline
        }
        if (t === "Happiness Quiz") {
          return "Press Start"
        }

        return "To Begin The Game"
      })
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <SEO
        title="Level up In Life with MooreMomentum"
        description="Want to level up in life but feel stuck? Our AI driven, science-backed gamification system makes it simple and fun to build momentum in life."
        link="https://mooremomentum.com/"
        image="https://images.ctfassets.net/8kplw9flsxgi/1UWjHItTdkTXE2scQvKJip/467a5059aaba6b863134615fd246fd8e/Emmett-Happy-Town-V2.png"
      />
      <Layout absoluteHeader>
        <div className="overflow-hidden">
          <div className="primary-gradient-bg pt-24 sm:pt-32 mb-12 md:pt-28 h-lvh">
            <div className="container mx-auto  px-4 ">
              <h1 className="font-Orbitron text-center text-white text-xl md:text-3xl lg:text-4xl mb-12 mt-2 max-w-screen-md m-auto">
                What if building healthy habits was as fun as playing your
                favorite game?
              </h1>

              <div className="md:flex items-center justify-center  hidden m-auto relative w-max">
                <video
                  width="auto"
                  height="auto"
                  loop={true}
                  autoPlay={true}
                  playsInline
                  muted
                >
                  <source src={video} type="video/mp4" />
                </video>
                <StaticImage
                  alt="Tedx"
                  className="sm:w-auto absolute"
                  src="../images/tedxdrop.png"
                  style={{
                    top: "-8%",
                    width: "90px",
                    left: "0",
                    position: "absolute",
                  }}
                />
              </div>

              <div className="md:hidden block m-auto relative">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<video className="app__backgroundVideo" autoPlay loop muted playsinline>
      <source src=${areasoflifevertical} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
                  }}
                />

                <img
                  alt="Tedx"
                  className="sm:w-auto absolute"
                  loading="lazy"
                  src={newtedx}
                  style={{
                    top: "-5%",
                    width: "90px",
                    left: "0",
                    position: "absolute",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                />
              </div>
              <OnScreen
                className="text-center text-white"
                classToAdd="scale-in-ver-center"
              >
                <div className="mt-4">
                  <TypedText
                    className="font-Press-Start text-center mx-auto text-coral leading-snug md:w-full"
                    text="READY PLAYER ONE???"
                  />
                  <div className="flex items-center justify-center  m-auto">
                    <Link to="/core-values-quiz/" target="_blank">
                      <img
                        className="m-auto"
                        src={reset}
                        alt="areas of life"
                        loading="lazy"
                        style={{ width: "50%" }}
                      />
                    </Link>
                  </div>
                </div>
              </OnScreen>
              {/* <div>
              <h3 className="font-Orbitron  text-shadow  text-2xl lg:leading-tight md:text-4xl xl:leading-tight mb-6 text-left">
                Upgrade All{" "}
                <span
                  className="text-coral "
                  style={{ textDecoration: "underline" }}
                >
                  {" "}
                  <Link to="/5-core-areas-of-life/"> 5 Cores</Link>:
                </span>
              </h3>
              <div className="flex md:text-xl  items-center   md:mt-2">
                <div className="mr-2" style={{ width: "60px" }}>
                  <StaticImage
                    alt="5 areas of life mind core of life"
                    placeholder="blurred"
                    src="../images/5-areas-of-life-brain.png"
                  />
                </div>
                <p className="text-black  text-left">
                  Develop A Growth Mindset
                </p>
              </div>
              <div className="flex   items-center  md:text-xl   md:mt-2">
                <div className="mr-2" style={{ width: "60px" }}>
                  <StaticImage
                    alt="5 areas of life financial freedom"
                    className="h-full w-full"
                    placeholder="blurred"
                    src="../images/dollar.png"
                  />
                </div>
                <p className="text-black  text-left">
                  Monetize that dormant biz idea
                </p>
              </div>
              <div className="flex  md:text-xl items-center   md:mt-2">
                <div className="mr-2" style={{ width: "60px" }}>
                  <StaticImage
                    alt="5 areas of life - communication core of life"
                    placeholder="blurred"
                    src="../images/5-areas-of-life-communication.png"
                  />
                </div>
                <p className="text-black text-left">
                  Become irresistible In Life & BIZ
                </p>
              </div>
              <div className="flex md:text-xl  items-center   md:mt-2">
                <div className="mr-2" style={{ width: "60px" }}>
                  <StaticImage
                    alt="5 areas of life physical health"
                    placeholder="blurred"
                    src="../images/dumble.png"
                  />
                </div>
                <p className="text-black md:text-black text-left">Get Abs</p>
              </div>

              <div className="flex md:text-xl items-center md:mt-2">
                <div className="mr-2" style={{ width: "60px" }}>
                  <StaticImage
                    alt="5 areas of life - emotinal core of life"
                    placeholder="blurred"
                    src="../images/5-areas-of-life-yoga.png"
                  />
                </div>
                <p className="text-black text-left">Eliminate Stress</p>
              </div>
            </div>
            <div className="text-right m-auto core-image">
              <StaticImage
                src="../images/kid-core-gamify-life.png"
                alt="areas of life"
              />
            </div> */}
            </div>
          </div>

          <div className="mx-auto mb-12 ">
            <OnScreen
              className="text-center text-white"
              classToAdd="scale-in-ver-center"
            >
              <div className="container text-center gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
                <div className="border-2 mb-4 border-coral pr-1 pl-1">
                  <p className="font-Orbitron text-shadow lg:leading-tight text-xl xl:text-2xl xl:leading-tight text-blue mb-6">
                    <span className="text-coral "> OUR PROMISE:</span> Spark and
                    sustain the momentum needed to continually level-up in life
                    by making change simple, fun, and ethically addictive.
                  </p>
                </div>
              </div>
            </OnScreen>
          </div>
          <OnScreen
            className="text-center text-white"
            classToAdd="scale-in-ver-center"
          >
            <div className=" mb-12 bg-gray py-12">
              <div className="container gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
                <h2 className="font-Orbitron ml-4 text-center text-3xl lg:text-4xl">
                  Learn How To Level Up In Life
                </h2>
                <div
                  className="text-left"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  <li>
                    🚀 Harness the power of Ai personalization and play to break
                    free from the monotony of life!
                  </li>
                  <li>
                    🚀 Replace willpower with a fun, rewarding, ethically
                    addictive system to put your habits on autopilot!
                  </li>
                  <li>
                    🚀 Spend less than 5 min/day with a simple, intuitive
                    one-stop shop to determine WHO you are, WHAT you want, and
                    HOW to get it.
                  </li>
                </div>
              </div>
            </div>
          </OnScreen>
          <div className=" mb-12">
            <div className="container mx-auto px-4 ">
              <OnScreen
                className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__repeat-2"
                classToAdd="scale-in-center slide"
              >
                <h2 className="font-Orbitron ml-4 text-coral text-center text-3xl lg:text-4xl mb-4">
                  The Problem <br />
                  <span className="font-Orbitron ml-4 text-coral text-center text-xl md:text-2xl mb-4">
                    {" "}
                    Momentum Killers
                  </span>
                </h2>
                <p className="text-left mb-12" style={{ fontSize: "18px" }}>
                  The Moore Momentum System addresses{" "}
                  <b> three critical challenges</b> in a modern, tech-fueled,
                  on-demand world:
                </p>
              </OnScreen>
              <div className="gap-4 grid grid-cols-1 sm:grid-cols-4 xl:gap-10 lg:grid-cols-3 ">
                <OnScreen
                  className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__repeat-2"
                  classToAdd="scale-in-center slide"
                >
                  <>
                    <div className="bg-white h-full p-6 relative rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                      <div className="flex items-center mb-8">
                        <div className="flex h-14  items-center justify-center p-3 w-20 md:w-32">
                          <StaticImage
                            alt="Tedx"
                            className="w-32 sm:w-auto"
                            src="../images/confused.png"
                          />
                        </div>
                        <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                          Information Overload and Generic Advice{" "}
                          {/* WHO you are */}
                        </h3>
                      </div>

                      <p className="text-link text-black">
                        <>
                          The overwhelming flood of conflicting information and
                          the prevalence of one-size-fits-all self-help
                          solutions make it difficult for individuals to discern
                          what will truly make them happy.
                        </>
                      </p>
                    </div>
                  </>
                </OnScreen>

                <OnScreen
                  className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-3s"
                  classToAdd="scale-in-center"
                >
                  <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                    <div className="flex items-center mb-8">
                      <div className="flex h-14  items-center justify-center p-3 w-16 md:w-28">
                        <StaticImage
                          alt="Tedx"
                          className="w-32 sm:w-auto"
                          src="../images/battery.png"
                        />
                      </div>
                      <h3 className="font-Orbitron text-coral text-xl xl:text-2xl">
                        Lack of Awareness and Personal Development{" "}
                      </h3>
                    </div>
                    <p className="text-link text-black">
                      The rapid pace of life and the constant lure of instant
                      gratification distract from long-term goals, undermining
                      the discipline needed to develop healthy habits in all ‘5
                      Cores’ for sustained happiness.
                    </p>
                  </div>
                </OnScreen>

                <OnScreen
                  className="sm:col-span-2 sm:col-start-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-5s"
                  classToAdd="scale-in-center"
                >
                  <div className="bg-white h-full p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                    <div className="flex items-center mb-8">
                      <div className="flex h-14  items-center justify-center p-3 w-24 md:w-32">
                        <StaticImage
                          alt="Tedx"
                          className="w-32 sm:w-auto"
                          src="../images/depressed.png"
                        />
                      </div>
                      <h3 className="font-Orbitron  text-coral text-xl xl:text-2xl">
                        Inaction, Conformity, and Limiting Beliefs{" "}
                      </h3>
                    </div>

                    <p className="text-link text-black">
                      Increased societal pressures and pervasive influences from
                      family, education, peers, and media promote conformity,
                      stifling individual growth and perpetuating a fixed
                      mindset.
                    </p>
                  </div>
                </OnScreen>
              </div>
            </div>
            <div className=" mb-12 ">
              <div className="container  mt-12 gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
                <h2 className="font-Orbitron text-center text-blue text-3xl lg:text-4xl">
                  THE SOLUTION <br />
                  <span className="font-Orbitron ml-4 text-blue text-center text-xl md:text-2xl mb-4">
                    Momentum Multipliers
                  </span>
                </h2>
                <div className="text-left">
                  <p className="text-link">
                    An{" "}
                    <b>
                      Ai driven, highly personalized, gamified habit
                      modification system
                    </b>{" "}
                    designed to reduce friction and build
                    <b>
                      {" "}
                      momentum in the{" "}
                      <Link to="/5-core-areas-of-life/">
                        5 Core Areas Of Life
                      </Link>
                    </b>
                    .
                  </p>
                </div>
                <h2 className="font-Orbitron  text-black text-xl xl:text-2xl text-xl">
                  Discover WHO you are, WHAT you want, and HOW to get it:
                </h2>
              </div>
            </div>
            <div className="container mx-auto px-4">
              <div className="gap-4 grid grid-cols-1 sm:grid-cols-4 xl:gap-10 lg:grid-cols-3 ">
                <OnScreen
                  className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__repeat-2"
                  classToAdd="scale-in-center slide"
                >
                  <Link to="/5-core-areas-of-life/">
                    <div className="bg-white  p-6 relative rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                      <div className="flex items-center mb-8">
                        <div className="flex h-14 icon-gray-bg items-center justify-center p-3 rounded-xl w-14">
                          <StaticImage
                            alt="Tedx"
                            className="w-32 sm:w-auto"
                            src="../images/rocket.webp"
                            style={{ transform: "rotate(40deg" }}
                          />
                        </div>

                        <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                          AWARENESS <br />
                          <span className="font-Orbitron text-black text-xl ">
                            <b>WHO</b> you are
                          </span>
                          {/* WHO you are */}
                        </h3>
                      </div>

                      <>
                        <div className="text-link">
                          {renderRichText(newData.who, {
                            renderMark: {
                              [MARKS.BOLD]: text => (
                                <span className="font-bold">{text}</span>
                              ),
                              [MARKS.ITALIC]: text => <em>{text}</em>,
                            },
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (node, children) => (
                                <p>{children}</p>
                              ),
                              [BLOCKS.HEADING_1]: (node, children) => (
                                <h1 className="font-Orbitron text-2xl">
                                  {children}
                                </h1>
                              ),
                              [BLOCKS.HEADING_2]: (node, children) => (
                                <h2 className="font-Orbitron text-xl">
                                  {children}
                                </h2>
                              ),
                              [BLOCKS.HEADING_3]: (node, children) => (
                                <h3 className="font-Orbitron text-lg">
                                  {children}
                                </h3>
                              ),
                              [BLOCKS.EMBEDDED_ASSET]: node => {
                                if (!node.data.target) {
                                  return
                                }
                                // console.log("Imagess",node.data.target.gatsbyImageData)
                                const { gatsbyImageData } = node.data.target
                                if (!gatsbyImageData) {
                                  // asset is not an image
                                  return null
                                }
                                return (
                                  <figure className="flex flex-wrap justify-center items-center">
                                    <GatsbyImage
                                      image={gatsbyImageData}
                                      alt="Blog Image"
                                    />
                                  </figure>
                                )
                              },
                            },
                          })}
                        </div>
                      </>
                    </div>
                  </Link>
                </OnScreen>

                <OnScreen
                  className="sm:col-span-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-3s"
                  classToAdd="scale-in-center"
                >
                  <Link to="/success-habits/">
                    <div className="bg-white p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                      <div className="flex items-center mb-8">
                        <div className="flex h-14 icon-teal-bg items-center justify-center p-3 rounded-xl w-14">
                          <svg
                            width="42"
                            height="41"
                            viewBox="0 0 42 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.2736 3.3085e-05C26.7112 0.00979757 31.6483 2.15257 35.2933 5.63594L38.2239 2.70537C39.4645 1.46479 41.5857 2.34343 41.5857 4.09792V15.0981C41.5857 16.1857 40.7041 17.0674 39.6164 17.0674H28.6163C26.8618 17.0674 25.9832 14.9462 27.2237 13.7055L30.6495 10.2797C28.117 7.90844 24.84 6.59491 21.3585 6.56488C13.7768 6.4994 7.38601 12.635 7.45157 20.4684C7.51376 27.8993 13.5382 34.1347 21.2362 34.1347C24.6109 34.1347 27.8003 32.9303 30.3138 30.7248C30.703 30.3834 31.2908 30.4042 31.6569 30.7702L34.9113 34.0247C35.3111 34.4244 35.2913 35.0762 34.8718 35.4551C31.2634 38.7144 26.4816 40.699 21.2362 40.699C9.99755 40.699 0.886801 31.5883 0.886719 20.3497C0.886637 9.12391 10.0478 -0.0200703 21.2736 3.3085e-05Z"
                              fill="#00A98F"
                            />
                          </svg>
                        </div>

                        <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                          VISION <br />
                          <span className="font-Orbitron text-black text-xl ">
                            <b>WHAT</b> you want
                          </span>
                          {/* WHAT you want */}
                        </h3>
                      </div>
                      <>
                        <div className="text-link">
                          {renderRichText(newData.what, {
                            renderMark: {
                              [MARKS.BOLD]: text => (
                                <span className="font-bold">{text}</span>
                              ),
                              [MARKS.ITALIC]: text => <em>{text}</em>,
                            },
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (node, children) => (
                                <p>{children}</p>
                              ),
                              [BLOCKS.HEADING_1]: (node, children) => (
                                <h1 className="font-Orbitron text-2xl">
                                  {children}
                                </h1>
                              ),
                              [BLOCKS.HEADING_2]: (node, children) => (
                                <h2 className="font-Orbitron text-xl">
                                  {children}
                                </h2>
                              ),
                              [BLOCKS.HEADING_3]: (node, children) => (
                                <h3 className="font-Orbitron text-lg">
                                  {children}
                                </h3>
                              ),
                              [BLOCKS.EMBEDDED_ASSET]: node => {
                                if (!node.data.target) {
                                  return
                                }
                                // console.log("Imagess",node.data.target.gatsbyImageData)
                                const { gatsbyImageData } = node.data.target
                                if (!gatsbyImageData) {
                                  // asset is not an image
                                  return null
                                }
                                return (
                                  <figure className="flex flex-wrap justify-center items-center">
                                    <GatsbyImage
                                      image={gatsbyImageData}
                                      alt="Blog Image"
                                    />
                                  </figure>
                                )
                              },
                            },
                          })}
                        </div>
                      </>
                    </div>
                  </Link>
                </OnScreen>

                <OnScreen
                  className="sm:col-span-2 sm:col-start-2 lg:col-span-1 animate__bounce animate__animated animate__bounce animate__delay-5s"
                  classToAdd="scale-in-center"
                >
                  <Link to="/gamify-your-life/">
                    <div className="bg-white p-6 rounded-md shadow-lg transform-none md:transform transition-transform hover:-translate-y-12">
                      <div className="flex items-center mb-8">
                        <div className="flex h-14 icon-yellow-bg items-center justify-center p-3 rounded-xl w-14">
                          <svg
                            width="46"
                            height="41"
                            viewBox="0 0 46 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M43.8787 5.08738H35.6117V1.90777C35.6117 0.850546 34.7611 0 33.7039 0H12.0825C11.0253 0 10.1748 0.850546 10.1748 1.90777V5.08738H1.90777C0.850546 5.08738 0 5.93793 0 6.99515V11.4466C0 14.2844 1.78853 17.2017 4.92045 19.4513C7.4244 21.2557 10.4689 22.4004 13.6644 22.766C16.1604 26.9075 19.0777 28.6165 19.0777 28.6165V34.3398H15.2621C12.4561 34.3398 10.1748 35.9853 10.1748 38.7913V39.7452C10.1748 40.2698 10.604 40.699 11.1286 40.699H34.6578C35.1824 40.699 35.6117 40.2698 35.6117 39.7452V38.7913C35.6117 35.9853 33.3303 34.3398 30.5243 34.3398H26.7087V28.6165C26.7087 28.6165 29.626 26.9075 32.122 22.766C35.3255 22.4004 38.37 21.2557 40.866 19.4513C43.9899 17.2017 45.7864 14.2844 45.7864 11.4466V6.99515C45.7864 5.93793 44.9359 5.08738 43.8787 5.08738ZM7.89339 15.3257C5.95382 13.9267 5.08738 12.3687 5.08738 11.4466V10.1748H10.1907C10.2701 12.7661 10.6517 15.0396 11.2081 17.0268C10.0078 16.6135 8.88702 16.0411 7.89339 15.3257ZM40.699 11.4466C40.699 12.7264 39.2921 14.3162 37.893 15.3257C36.8994 16.0411 35.7706 16.6135 34.5703 17.0268C35.1268 15.0396 35.5083 12.7661 35.5878 10.1748H40.699V11.4466Z"
                              fill="#FFC629"
                            />
                          </svg>
                        </div>

                        <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl text-break">
                          ACTION <br />
                          <span className="font-Orbitron text-black text-xl ">
                            <b>HOW</b> to get it
                          </span>
                          {/* HOW to get it */}
                        </h3>
                      </div>

                      <>
                        <div className="text-link">
                          {renderRichText(newData.how, {
                            renderMark: {
                              [MARKS.BOLD]: text => (
                                <span className="font-bold">{text}</span>
                              ),
                              [MARKS.ITALIC]: text => <em>{text}</em>,
                            },
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (node, children) => (
                                <p>{children}</p>
                              ),
                              [BLOCKS.HEADING_1]: (node, children) => (
                                <h1 className="font-Orbitron text-2xl">
                                  {children}
                                </h1>
                              ),
                              [BLOCKS.HEADING_2]: (node, children) => (
                                <h2 className="font-Orbitron text-xl">
                                  {children}
                                </h2>
                              ),
                              [BLOCKS.HEADING_3]: (node, children) => (
                                <h3 className="font-Orbitron text-lg">
                                  {children}
                                </h3>
                              ),
                              [BLOCKS.EMBEDDED_ASSET]: node => {
                                if (!node.data.target) {
                                  return
                                }
                                // console.log("Imagess",node.data.target.gatsbyImageData)
                                const { gatsbyImageData } = node.data.target
                                if (!gatsbyImageData) {
                                  // asset is not an image
                                  return null
                                }
                                return (
                                  <figure className="flex flex-wrap justify-center items-center">
                                    <GatsbyImage
                                      image={gatsbyImageData}
                                      alt="Blog Image"
                                    />
                                  </figure>
                                )
                              },
                            },
                          })}
                        </div>
                      </>
                    </div>
                  </Link>
                </OnScreen>
              </div>
              <h2 className="font-Orbitron  text-black text-xl xl:text-2xl text-xl mt-12">
                Combined, these 3 <i>Transformative Elements</i> ensure the
                holistic habit transformation is something you WANT to do, not
                have to.{" "}
              </h2>
              <div className="flex items-center justify-center  mt-2 m-auto">
                <Link to="/core-values-quiz/" target="_blank">
                  <img
                    className="m-auto"
                    src={reset}
                    alt="areas of life"
                    loading="lazy"
                    style={{ width: "50%" }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className=" mb-12 bg-gray py-12 my-12">
            <div className="container  gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
              <h2 className="font-Orbitron text-black text-center text-3xl lg:text-4xl">
                What Does It Mean To Level Up In Life?{" "}
              </h2>
              <h2 className="font-Orbitron text-center text-black text-xl xl:text-2xl text-xl">
                Get ‘The Equation Of Life’ working FOR instead of against you:
              </h2>
              <div className="m-auto flex ">
                <div
                  className=" bg-blue equation  mx-auto p-4 rounded-md shadow-lg slide-in-left"
                  style={{ width: "70%", margin: "0 auto" }}
                >
                  <StaticImage
                    alt="Equation of Life for success habits"
                    placeholder="blurred"
                    src="../images/equation-of-life-v2.jpg"
                    className="equation"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-full px-4 lg:container md:mx-auto">
            <h2 className="font-Orbitron my-12 text-center text-3xl sm:mb-8 lg:text-4xl lg:mt-16">
              {newData.stepsHeading}
            </h2>

            <Timeline events={events} />
            <div>
              <div className="text-center">
                <h2 className="text-2xl mt-4 text-bold ">
                  <b>What level are you currently stuck on?</b>
                </h2>
                <div className="mt-8 mb-12">
                  <StartButton className="bg-blue py-3 px-12 rounded-full text-white" />
                </div>
                {/* <StartButton alt className="mx-auto mt-10" /> */}
              </div>
            </div>
          </div>
          <div className="bg-gray py-12">
            <div className="max-w-full px-4 lg:container md:mx-auto">
              <h2 className="font-Orbitron  text-center text-3xl sm:mb-8 lg:text-4xl">
                How To Win At Life In All 5 Cores{" "}
              </h2>
              <NewTimeline events={newEvents} />
            </div>
          </div>

          <div className="max-w-6xl my-12 mx-auto px-4 md:my-24">
            <h2 className="font-Orbitron mb-8 text-center text-3xl">
              As Seen On
            </h2>

            <div className="flex flex-wrap items-center sm:flex-nowrap sm:space-x-8 lg:space-x-16">
              <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
                <a
                  href="https://www.youtube.com/watch?v=KNIDiu0s59o&ab_channel=GamifyYourLifew%2FWillMoore"
                  target="_blank"
                >
                  <StaticImage
                    alt="Tedx"
                    className="w-32 sm:w-auto"
                    src="../images/tedx.png"
                  />
                </a>
              </div>

              <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
                <StaticImage
                  alt="ABC"
                  className="h-24 w-32 sm:w-auto"
                  objectFit="contain"
                  src="../images/abc.png"
                />
              </div>

              <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
                <StaticImage
                  alt="NBC"
                  className="w-32 sm:w-auto"
                  src="../images/nbc.png"
                />
              </div>

              <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
                <StaticImage
                  alt="Entrepreneur"
                  className="w-32 sm:w-auto"
                  src="../images/entrepreneur.png"
                />
              </div>

              <div className="text-center w-1/2 sm:w-auto">
                <StaticImage
                  alt="OBJ"
                  className="w-24 sm:w-auto"
                  src="../images/obj.png"
                />
              </div>

              <div className="text-center w-1/2 sm:w-auto">
                <a
                  href="https://www.youtube.com/watch?v=UEwilrcj3WM"
                  target="_blank"
                >
                  <StaticImage
                    alt="CW"
                    className="w-32 sm:w-auto"
                    src="../images/cw.png"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="mb-16 lg:my-24">
            <h2 className="font-Orbitron mb-8 mt-16 text-center text-3xl lg:text-4xl">
              Testimonials
            </h2>

            <div className="max-w-5xl mx-auto mt-8 px-4">
              <div className="space-y-8">{getTestimonials()}</div>
            </div>

            <div className="mt-16 text-center">
              <Link to="/success-stories/">
                <Button>View More</Button>
              </Link>
            </div>
          </div>

          <OnScreen
            className="max-w-5xl mx-auto px-4"
            classToAdd="slide-in-left"
          >
            <div className="flex justify-center mock-shadow">
              <svg
                className="hidden w-auto sm:block sm:h-64 lg:h-72 2xl:h-80"
                width="198"
                height="518"
                viewBox="0 0 198 518"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M190.5 15V7.5H183H115C55.6294 7.5 7.5 55.6294 7.5 115V403C7.5 462.371 55.6294 510.5 115 510.5H183H190.5V503V15Z"
                  fill="#55BEE0"
                  stroke="black"
                  strokeWidth="15"
                />
                <circle
                  className="animate-pulse"
                  cx="116.5"
                  cy="137.5"
                  r="39.5"
                  fill="black"
                />
                <circle cx="112" cy="240" r="19" fill="black" />
                <circle cx="77" cy="273" r="19" fill="black" />
                <circle cx="147" cy="273" r="19" fill="black" />
                <circle cx="112" cy="309" r="19" fill="black" />
                <rect
                  x="125"
                  y="359"
                  width="31"
                  height="31"
                  rx="3"
                  fill="black"
                />
              </svg>

              <div className="bg-blue flex flex-1 flex-col justify-center rounded-md px-4 py-8 text-center text-white sm:rounded-none">
                <div>
                  <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
                    How Happy are You?
                  </h2>

                  <p className="mx-auto lg:w-1/2">
                    Get your Core Score to determine your first step to building
                    your momentum and firing on all cylinders.
                  </p>

                  <div className="mt-8">
                    <StartButton className="bg-white py-3 px-12 rounded-full text-blue" />
                  </div>
                </div>
              </div>

              <svg
                className="hidden w-auto sm:block sm:h-64 lg:h-72 2xl:h-80"
                width="198"
                height="518"
                viewBox="0 0 198 518"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 15V7.5H15H83C142.371 7.5 190.5 55.6294 190.5 115V403C190.5 462.371 142.371 510.5 83 510.5H15H7.5V503V15Z"
                  fill="#E3675B"
                  stroke="black"
                  strokeWidth="15"
                />
                <circle
                  className="animate-pulse"
                  cx="83.5"
                  cy="288.5"
                  r="39.5"
                  fill="black"
                />
                <circle cx="83" cy="117" r="19" fill="black" />
                <circle cx="48" cy="150" r="19" fill="black" />
                <circle cx="118" cy="150" r="19" fill="black" />
                <circle cx="83" cy="186" r="19" fill="black" />
                <rect
                  x="36"
                  y="359"
                  width="31"
                  height="31"
                  rx="3"
                  fill="black"
                />
              </svg>
            </div>
          </OnScreen>

          <div className="bg-gray py-12 lg:mt-32">
            <div className="container mx-auto px-4">
              <h2 className="text-center mb-16 text-3xl lg:text-4xl font-Orbitron">
                Build Momentum To Win At Life
              </h2>
              <div className="gap-8 grid grid-cols-1 items-center md:grid-cols-2">
                <div className="order-2 md:order-1">
                  <div className="text-link">
                    {renderRichText(newData.firstSection, {
                      renderMark: {
                        [MARKS.BOLD]: text => (
                          <span className="font-bold">{text}</span>
                        ),
                        [MARKS.ITALIC]: text => <em>{text}</em>,
                      },
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p>{children}</p>
                        ),
                        [BLOCKS.HEADING_1]: (node, children) => (
                          <h1 className="font-Orbitron text-4xl">{children}</h1>
                        ),
                        [BLOCKS.HEADING_2]: (node, children) => (
                          <h2 className="font-Orbitron text-3xl">{children}</h2>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3 className="font-Orbitron text-lg">{children}</h3>
                        ),
                        [BLOCKS.EMBEDDED_ASSET]: node => {
                          if (!node.data.target) {
                            return
                          }
                          // console.log("Imagess",node.data.target.gatsbyImageData)
                          const { gatsbyImageData } = node.data.target
                          if (!gatsbyImageData) {
                            // asset is not an image
                            return null
                          }
                          return (
                            <figure className="flex flex-wrap justify-center items-center">
                              <GatsbyImage
                                image={gatsbyImageData}
                                alt="Blog Image"
                              />
                            </figure>
                          )
                        },
                      },
                    })}
                  </div>

                  <div className="mt-8">
                    <StartButton className="py-3 bg-coral px-12 rounded-full text-sm text-white" />
                  </div>
                </div>

                <div className="order-1 md:order-2">
                  <div className="max-w-md mx-auto relative shadow-lg rounded-md ">
                    <StaticImage
                      alt="Jumping At The Beach"
                      className="z-10 rounded-t"
                      placeholder="blurred"
                      src="../images/momentum.png"
                    />
                    <StaticImage
                      alt="Jumping At The Beach"
                      className="z-10 rounded-b"
                      placeholder="blurred"
                      src="../images/levellingup.webp"
                    />

                    <OnScreen
                      className="absolute right-0 -top-24"
                      classToAdd="ghost-slide-lr"
                    >
                      <Ghosts />
                    </OnScreen>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="px-4">
            <OnScreen
              className="bg-gray max-w-4xl mx-auto my-20 px-4 py-8 rounded-md shadow-lg"
              classToAdd="slide-in-left"
            >
              <div className="text-blue text-center">
                <div className="text-link">
                  {renderRichText(newData.mission, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-2xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron text-xl">{children}</h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-lg">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </div>

              <Link to="/core-values-quiz/">
                <TypedText
                  className="font-Press-Start mt-8 mx-auto text-center text-coral text-4xl leading-snug md:w-full"
                  text="READY PLAYER ONE???"
                />
              </Link>
            </OnScreen>
          </div> */}
          <div className=" pb-16  lg:mt-16">
            <div className="container 12mt- mx-auto px-4 ">
              <h2 className="text-center mb-16 text-3xl lg:text-4xl font-Orbitron">
                What does it mean to level up in life?{" "}
              </h2>
              <div className="text-link">
                {renderRichText(newData.secondSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-lg">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure
                          className="flex flex-wrap justify-center items-center equation"
                          style={{
                            width: "70%",
                            height: "100%",
                            margin: "0 auto",
                          }}
                        >
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
              <div className="page-text">
                <h3 className="font-Orbitron text-lg mb-1">
                  {" "}
                  THE 5 CORE{" "}
                  <Link className="link" to="/5-core-areas-of-life/">
                    AREAS OF LIFE
                  </Link>
                </h3>
                <p className="text-link">
                  {" "}
                  The first step to gaining momentum in our success loop
                  involves understanding where we stand in, and how to harness,
                  the 5 Core Areas of Life correlated to happiness, health, and
                  success. It's crucial to become self aware of each, which
                  include your:
                </p>{" "}
                <div className="flex items-center ">
                  <div className="mr-2 " style={{ width: "45px" }}>
                    <StaticImage
                      alt="Will Moore Pixelated"
                      placeholder="blurred"
                      src="../images/dumble.png"
                    />
                  </div>
                  <p className="text-left">
                    Look good, feel good, and be propelled by sustainable
                    energy.
                  </p>
                </div>
                <div className="flex items-center  mt-2">
                  <div className="mr-2" style={{ width: "40px" }}>
                    <StaticImage
                      alt="5 areas of life - financial core of life"
                      placeholder="blurred"
                      src="../images/dollar.png"
                    />
                  </div>
                  <p className="text-left">
                    Harness your strengths and passions to catapult your wealth.
                  </p>
                </div>
                <div className="flex items-center  mt-2">
                  <div className="mr-2" style={{ width: "40px" }}>
                    <StaticImage
                      alt="5 areas of life - emotional core of life"
                      placeholder="blurred"
                      src="../images/5-areas-of-life-yoga.png"
                    />
                  </div>
                  <p className="text-left">
                    Minimize stress and proactively incorporate your passions
                    daily.{" "}
                  </p>
                </div>
                <div className="flex items-center mt-2">
                  <div className="mr-2" style={{ width: "40px" }}>
                    <StaticImage
                      alt="5 areas of life - mindset core of life"
                      placeholder="blurred"
                      src="../images/5-areas-of-life-brain.png"
                    />
                  </div>
                  <p className="text-left">
                    Give fear the finger and replace your fixed mindset with a
                    positive mindset centered around growth.
                  </p>
                </div>
                <div className="flex items-center  mt-2">
                  <div className="mr-2" style={{ width: "40px" }}>
                    <StaticImage
                      alt="5 areas of life - communication core of life"
                      placeholder="blurred"
                      src="../images/5-areas-of-life-communication.png"
                    />
                  </div>
                  <p className="text-left">
                    Develop your habits around the #1 secret to deep-fulfilling
                    relationships.
                  </p>
                </div>
                <p className="my-5">
                  Using a rocket as analogy, each of your 5 Cores act as the
                  thrusters for your engine.
                </p>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<video className="app__backgroundVideo" controls loop muted  playsinline>
      <source src=${successLoop} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
                  }}
                  className="equation bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg slide-in-left"
                  style={{ width: "70%", margin: "0 auto" }}
                />
                <br />
                <p className="mt-3 mb-1">
                  Your habits then act as the fuel cells for each of these
                  thrusters. So the more bad habits you’re able to REPLACE with
                  good habits in each core, the more momentum you’ll build.
                </p>
                <br />
                <p className="mt-1 mb-3">
                  This is the basis for self improvement in your life's video
                  game, and what will allow you to win the game of life.{" "}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<video className="app__backgroundVideo" controls loop muted  playsinline>
      <source src=${successFailure} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
                  }}
                  className="equation bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg slide-in-left"
                  style={{ width: "70%", margin: "0 auto" }}
                />
                <h3 className="font-Orbitron text-lg mt-4 mb-1">
                  HOW TO INCREASE MOMENTUM IN THE 5 CORE AREAS OF LIFE?{" "}
                </h3>
                <StaticImage
                  alt="Momentify definition"
                  placeholder="blurred"
                  src="../images/momentify.png"
                  className="equation"
                  style={{ width: "70%", margin: "0 auto" }}
                />
                <p>
                  <b>The Moore Momentum System</b> is a fun, AI driven,
                  science-based solution to spark and sustain the momentum
                  needed to thrive in your success loop.
                </p>
                <p className="text-link">
                  Our approach leverages behavioral science and proven
                  principles to replace the overwhelming influx of misguided and
                  generic advice with an innovative,{" "}
                  <Link to="/gamify-your-life/" target="_blank">
                    {" "}
                    gamifified
                  </Link>{" "}
                  platform. Allowing you to build the discipline, and
                  accompanying habits necessary to reach any goal, or level, you
                  set your sights on.
                </p>
                <p className="text-center my-2">
                  <b>
                    "Discipline is doing the things you don't want to do in
                    order to become the person you do."
                  </b>{" "}
                  <br />
                  Will Moore - founder of Moore Momentum
                </p>
                <p>
                  We've engineered our platform to harness the same techniques
                  used by big tech to get you hooked on their products to pad
                  their bottom line. Only we use these mechanisms to get you
                  ethically addicted to building momentum in life.
                </p>
                <br />
                <p>
                  The idea is to reduce the friction of change and habit
                  formation by shifting your fixed mindset that views change as
                  boring, hard, and insurmountable, to a <b> growth mindset</b>{" "}
                  that sees it as
                  <b>simple,</b>, <b>fun</b>, and <b>INEVITABLE</b>.
                </p>
                <br />
                <p>
                  Ultimately helping you and/or your business determine WHO you
                  are, WHAT you want, and HOW to get it.
                </p>
                <br />
                <h3 className="font-Orbitron text-lg mt-4 mb-1">
                  THE 3 MOMENTUM BOOSTING ELEMENTS OF THE MM SYSTEM:{" "}
                </h3>
                <br />
                <ul className="list">
                  <li>
                    <b>AWARENESS (WHO you are): </b>
                    <p className="pl-3">
                      Pinpoint the pain points and related bad habits stifling
                      your growth in all 5 Cores.
                    </p>
                  </li>
                  <li className="mt-2">
                    <b>VISION (WHAT you want): </b>
                    <p className="pl-3">
                      Paint a vivid picture of the fully upgraded version of
                      yourself in all 5 Cores and determine the personalized
                      goals and habit modifications to take you there
                    </p>
                  </li>
                  <li className="mt-2">
                    <b>ACTION/ACCOUNTABILITY/AUTOMATICITY (HOW to get it): </b>
                    <p className="pl-3">
                      Adopt a gamified accountability system that harnesses AI
                      and deep personalization to make it simple, fun, and
                      rewarding to achieve those goals; maintaining holistic
                      balance and continual momentum.
                    </p>
                  </li>
                </ul>
                <p>
                  Combined, these 3 Transformative Elements ensure the process
                  of holistic habit transformation is enjoyable, effective, and
                  automatic, and that building the discipline and momentum
                  required to do so is not something you have to do, but
                  <b> WANT</b>
                  to.
                </p>
                <h3 className="font-Orbitron text-lg mt-4 mb-1">
                  HABITS PERSONALIZED TO YOUR DNA{" "}
                </h3>
                <p>
                  The one-size-fits all typical approach to habit transformation
                  doesn’t work. Both individuals and businesses are 100% unique,
                  so why would you try the exact same strategy when trying to
                  make improvements in each?
                </p>
                <br />
                <p>
                  The MM system customizes each plan to maximally reduce the
                  friction involved in reshaping the habits in each of the 5
                  Cores.
                </p>
                <br />
                <p>
                  For instance, if you’re looking to work out more, we’ll hone
                  in on your passions, strengths, and lifestyle factors to
                  determine your<b> ‘golden habit’ </b>that maximally reduces
                  the friction involved in establishing a regular routine. When
                  the MM founder Will Moore ran into this pain point in his
                  life, he used the system to determine his golden habit was
                  playing in a basketball league after his kids went to bed.{" "}
                </p>
                <h3 className="font-Orbitron text-lg mt-4 mb-1">
                  HARNESS THE EQUATION OF LIFE:
                </h3>
                <p>
                  The MM System is centered around getting what Will Moore calls{" "}
                  <b>‘THE EQUATION OF LIFE’</b> working FOR instead of against
                  you.
                </p>
                <div className="m-auto flex my-3">
                  <StaticImage
                    alt="Equation of Life for success habits"
                    placeholder="blurred"
                    src="../images/equation-of-life-v2.jpg"
                    className="equation"
                    style={{ width: "70%", margin: "0 auto" }}
                  />
                </div>
                <p className="text-link">
                  The key is to reprogram that faulty belief system with one
                  based on proven principles and personal{" "}
                  <Link to="/core-values-quiz/" target="_blank">
                    {" "}
                    core values
                  </Link>{" "}
                  to allow you to take the necessary actions repeatedly long
                  enough for them to form healthy, momentum building habits in
                  all 5 Cores.{" "}
                </p>
                <br />
                <p>
                  Remember, your habits don’t care if they’re helping or hurting
                  you. Either way they’ll compound over time. Who will your
                  habits compound you into in 20 years?
                </p>
                <br />
                <p>
                  The alternative to all this? <b>Willpower</b>; which the
                  majority of us rely on, yet ALWAYS runs out because its power
                  source derives from wishing and hoping vs fundamentally
                  changing who you are at your core - all 5 of them 🚀🚀🚀.
                </p>
                <br />
                <p>
                  Your car can’t run on fumes, and neither can the rocket
                  propelling your life.
                </p>
                <h3 className="font-Orbitron text-lg mt-4 mb-1">
                  WINNING AT LIFE, YOUR EPIC QUEST{" "}
                </h3>
                <p>
                  Winning in life won’t be easy - no fun game worth playing ever
                  is.
                </p>
                <br />
                <p>
                  There is no <i>"Contra Cheat Code"</i> (if you know you know)
                  to warp from level 1 to 100, but instead it’s about building
                  enough momentum to go from level 1 to level 2, then 3, and so
                  on.
                </p>
                <p>
                  Your self development journey, but if you’re able to get the
                  ‘Equation Of Life’ working for you to make minuscule, barely
                  noticeable, incremental changes every day, your growth and
                  happiness will become inevitable.
                </p>
                <br />
                <p className="text-center">
                  <b>
                    "Tiny daily improvements accumulate over time to form
                    massive results. Improving by 1% on its own isn’t
                    remarkable, but if you improve by 1% every single day one
                    core at a time, it will compound into something remarkable.”
                  </b>
                  <br />- James Clear
                </p>
                <br />
                <p>
                  Your challenge is to gain enough momentum to break earth’s
                  gravitational pull, then to sustain that growth as you explore
                  all the universe has to offer and fire on all cylinders in all
                  5 Cores.
                </p>
                <br />
                <p>
                  It’s on this journey you’ll gain valuable insights and
                  rediscover that wonder, passion, and enthusiasm you once had
                  as a kid before life got in the way. You'll then naturally
                  want to pay that personal development progress forward to help
                  the rest of the world build some momentum of its own.
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<video className="app__backgroundVideo" controls loop muted  playsinline>
      <source src=${cylinder} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
                  }}
                  className="equation bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg slide-in-left"
                  style={{ width: "70%", margin: "0 auto" }}
                />
                <h3 className="font-Orbitron text-lg mt-4 mb-1">
                  Shall We Play A Game?{" "}
                </h3>
                <p>
                  Find out NOW what level you’re currently stuck on, and what it
                  will take to break earth’s gravitational pull to fly up into
                  that bright, bold, beautiful sky.{" "}
                </p>
                <br />
                <p>
                  <b>Ready Player One?</b>
                </p>
                <div className="flex items-center justify-center  mt-2 m-auto">
                  <Link to="/core-values-quiz/" target="_blank">
                    <img
                      className="m-auto"
                      src={reset}
                      alt="areas of life"
                      loading="lazy"
                      style={{ width: "50%" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mb-12 ">
            <div className="container text-center  gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
              <div className="border-2 mb-4 border-coral pr-1 pl-1">
                <p className="font-Orbitron pt-4 text-shadow lg:leading-tight text-xl xl:text-2xl xl:leading-tight text-blue mb-6">
                  <span className="text-coral pl-2 pr-1"> OUR PLEDGE:</span>
                  <span>
                    {renderRichText(newData.mission, {
                      renderMark: {
                        [MARKS.BOLD]: text => (
                          <span className="font-bold">{text}</span>
                        ),
                        [MARKS.ITALIC]: text => <em>{text}</em>,
                      },
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <span>{children}</span>
                        ),
                        [BLOCKS.HEADING_1]: (node, children) => (
                          <h1 className="font-Orbitron text-4xl">{children}</h1>
                        ),
                        [BLOCKS.HEADING_2]: (node, children) => (
                          <h2 className="font-Orbitron text-3xl">{children}</h2>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3 className="font-Orbitron text-lg">{children}</h3>
                        ),
                        [BLOCKS.EMBEDDED_ASSET]: node => {
                          if (!node.data.target) {
                            return
                          }
                          // console.log("Imagess",node.data.target.gatsbyImageData)
                          const { gatsbyImageData } = node.data.target
                          if (!gatsbyImageData) {
                            // asset is not an image
                            return null
                          }
                          return (
                            <figure className="flex flex-wrap justify-center items-center">
                              <GatsbyImage
                                image={gatsbyImageData}
                                alt="Blog Image"
                              />
                            </figure>
                          )
                        },
                      },
                    })}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <OnScreen classToAdd="slide-in-right">
            <div className="container mb-12 mx-auto px-4 md:mb-24">
              <div className="grid rounded-md shadow-lg">
                <StaticImage
                  alt="App Background Screen"
                  className="space-bg rounded-md"
                  layout="fullWidth"
                  objectPosition="57% 57%"
                  placeholder="blurred"
                  quality={100}
                  src="../images/index-app-bg.png"
                  style={{ gridArea: "1/1" }}
                />

                <div className="grid relative" style={{ gridArea: "1/1" }}>
                  <div className="absolute bg-black bg-opacity-30 inset-0 rounded-md" />

                  <div className="absolute bottom-0 hidden left-0 z-0 md:block md:w-36 xl:w-full">
                    <StaticImage
                      alt="Alien"
                      className="w-32"
                      placeholder="blurred"
                      src="../images/alien.png"
                    />
                  </div>

                  <div className="gap-16 grid grid-cols-1 pt-8 px-8 text-white z-10 md:gap-4 md:grid-cols-2 md:pt-0 md:px-0">
                    <div className="max-w-md mx-auto space-y-8 z-10 md:max-w-lg md:pl-8 md:pt-8 2xl:self-center">
                      <h2 className="font-Orbitron text-2xl lg:text-4xl">
                        Learn more about the Gamify Your Life App
                      </h2>

                      <p>
                        {/* Be the Pilot of your own rocket ship and learn how to fire
                      on all cylinders. Click below to learn more and be the
                      first to know when our app is released! */}
                        The culmination of 25 years of research, the app will
                        allow you to truly gamify your life by making it fun and
                        addicting to gain momentum. As you level-up on screen,
                        you'll also level up in real life!
                      </p>

                      <Button href="/weekly-habit-tracker-app/" link="GATSBY">
                        Learn More
                      </Button>
                    </div>

                    <div className="flex items-end justify-center sm:max-w-xs sm:mx-auto">
                      <StaticImage
                        alt="Iphone App Splash Screen"
                        placeholder="blurred"
                        src="../images/app-mock-index.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OnScreen>

          {/* <div id="phaser-container" /> */}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allContentfulSuccessStory(
      filter: {
        name: { in: ["Linus Stieldorf", "Mark Hansen", "Danny Lynch"] }
      }
    ) {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          name
          testimonial {
            testimonial
          }
          title
        }
      }
    }
    allContentfulHomePage {
      edges {
        node {
          headline
          subHeading
          stepsHeading

          stepOne {
            raw
          }
          stepTwo {
            raw
          }
          stepThree {
            raw
          }
          stepFour {
            raw
          }
          stepFive {
            raw
          }
          stepSix {
            raw
          }
          stepSeven {
            raw
          }
          what {
            raw
          }
          who {
            raw
          }
          how {
            raw
          }
          firstSection {
            raw
          }
          mission {
            raw
          }
          secondSection {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
